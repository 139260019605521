import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/Device',  // 重定向到主页
    children: [   { path: 'Device', name: 'Device', meta: { name: '设备管理' },component: () => import('../views/manager/Device.vue') },
                  { path: 'User', name: 'User',meta: { name: '用户信息' }, component: () => import('../views/manager/User.vue') },
                  { path: 'record', name: 'Record', meta: { name: '通行记录' }, component: () => import('../views/manager/Record.vue') },
                  { path: 'Analysis', name: 'Analysis', meta: { name: '数据分析' }, component: () => import('../views/manager/Analysis.vue') },
                  { path: 'person', name: 'Person', meta: { name: '个人信息' }, component: () => import('../views/manager/Person.vue') },
                  { path: 'password', name: 'Password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
                  { path: 'Facialdata', name: 'Facialdata', meta: { name: '人脸数据' }, component: () => import('../views/manager/Facialdata') },

                  { path: '403', name: 'Auth', component: () => import('../views/Auth.vue') },
    ]
  },
  {
    path: '/login',
    name: 'home',
    component: Login
    //component: () => import('../views/HomeView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes
})

router.beforeEach((to, from, next) => {
  // to 是到达的路由信息
  // from 是开源的路由信息
  // next 是帮助我们跳转路由的函数
  let adminPaths = ['/user']
  let user = JSON.parse(localStorage.getItem('honey-user') || '{}')

  if (user.role !== 'admin' && adminPaths.includes(to.path)) {
    // 如果当前登录的用户不是管理员，然后当前的到达的路径是管理员才有权限访问的路径，那这个时候我就让用户去到一个没有权限的页面，不让他访问实际的页面
    next('/403')
   // next()
  } else {
    next()
  }
})

export default router
